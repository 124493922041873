<template>
  <div class="arrearage-wrapper">
    <van-form class="form-wrapper">
      <van-field v-model="currentOrderItem.money" input-align="right" readonly label="订单金额" />
      <van-field v-model="currentOrderItem.havePayMoney" input-align="right" readonly label="已付金额" />
      <van-field
        v-model="state.payMoney"
        @input="changeMoney(state.payMoney)"
        type="number"
        input-align="right"
        placeholder="请输入"
        label="本次支付金额"
      />
      <van-field v-model="currentArrearage" input-align="right" readonly label="剩余欠款金额" />
    </van-form>
    <div class="footer-wrapper">
      <my-button-group
        rightText="提交审核"
        :loadingRight="loading"
        :isShowLeft="false"
        @handleButtonRight="payOweMoneys"
      ></my-button-group>
    </div>
  </div>
</template>
<script>
import { Form, Field, Toast } from "vant";
import { reactive, computed, ref } from "vue";
import myButtonGroup from "@/components/buttonGroup";
import { useRoute, useRouter } from "vue-router";
import { payOweMoney } from "@/api/common";
export default {
  components: {
    myButtonGroup,
    [Form.name]: Form,
    [Field.name]: Field,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const currentOrderItem = reactive({
      sn: route.query.sn,
      money: route.query.money,
      havePayMoney: route.query.havePayMoney,
    });
    const currentArrearage = computed(() => {
      return (
        currentOrderItem.money -
        currentOrderItem.havePayMoney -
        state.payMoney
      ).toFixed(2);
    });
    const state = reactive({
      payMoney: "",
    });
    const changeMoney = (val) => {
      const maxMoney = Number(
        currentOrderItem.money - currentOrderItem.havePayMoney
      );
      if (Number(val) >= maxMoney) {
        state.payMoney = maxMoney;
      } else if (Number(val) < 0) {
        state.payMoney = 0;
      } else if (val === "-") {
        state.payMoney = 0;
      }
    };
    const payOweMoneys = () => {
      if (!state.payMoney) {
        return Toast({
          message: "请输入金额!",
        });
      }
      loading.value = true;
      const params = {
        sn: route.query.sn,
        payMoney: state.payMoney,
      };
      payOweMoney(params).then((res) => {
        loading.value = false;
        if (res && res.body) {
          Toast({
            message: "操作成功!",
            onClose: () => {
              router.push({
                path: "/client",
              });
            },
          });
        }
      });
    };
    return {
      state,
      loading,
      currentArrearage,
      currentOrderItem,
      changeMoney,
      payOweMoneys,
    };
  },
};
</script>
<style lang="less" scoped>
.arrearage-wrapper {
  height: 100%;
  background: #f5f5f5;
  padding: 24px;
  box-sizing: border-box;
  .form-wrapper {
    background: #fff;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 24px 32px;
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
  }
}
</style>